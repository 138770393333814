.btn-login {
    font-weight: bold;
    color: rgba(20, 27, 155, 0.75);
    border: 2px solid rgba(20, 27, 155, 0.75);
}

.btn-login:hover {
    color: white;
    background-color: rgba(20, 27, 155, 0.75);
    border-color: rgba(20, 27, 155, 0.75);
}

.btn-login:active {
    color: white;
    background-color: rgba(20, 27, 155, 0.75);
    border-color: transparent;
}

.logged-out-msg{
    background: rgba(255,255,0,0.15);
    padding: 0.25rem 1.25rem;
}