.sidebar {
    width: 170px;
}

.sidebar-user-info-icon {
    min-width: 16px;
}

.sidebar-user-info .text {
    font-size: 0.85rem !important;
}

.sidebar-menu {

}

.sidebar-menu-item {
    padding: 6px 4px;
    color: var(--tc-gray) !important;
    font-size: 1rem;
    font-weight: 400;
    font-family: campton, sans-serif;
}

.sidebar-menu-item:hover {
    cursor: pointer !important;
    color: var(--tc-blue-soft) !important;
    background: rgba(20, 27, 155, 0.025) !important;
}

.sidebar-menu-item.active {
    background: var(--tc-widget-bc) !important;
    color: var(--tc-blue-soft) !important;
    border-left: 3px solid var(--tc-blue-soft);
}

.sidebar-menu-icon {
    min-width: 36px;
}