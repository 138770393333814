:root {
    /*tc->TellusR Color*/
    --tc-primary: #11a09b;
    --tc-light: #ececec;
    --tc-green: #40b08e;
    --tc-gray: #808080;
    --tc-blue: #141b9b;
    --tc-soft-black: rgba(0, 0, 0, 0.80);
    --tc-blue-soft: rgba(20, 27, 155, 0.70);
    --tc-widget-bc: rgba(20, 27, 155, 0.10);
    --z-index-tellusr: 2000;
}

.text {
    color: var(--tc-gray) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.tellusr-nav-item {
    background: rgba(180, 180, 180, 0.1);
    border-radius: 8px;
    color: #777777;
    font-size: 1.1rem;
    font-weight: 400;
    font-family: campton, sans-serif;
    margin: auto;
    padding: 6px 12px;
    cursor: pointer;
}

.tellusr-header-logo {
    width: 150px;
    height: auto;
}

.tellusr-header-container {

}

.tellusr-content-container {
    min-height: 450px;
}

.widget-container {
    background: var(--tc-widget-bc);
    max-height: 80vh;
}

.widget-header-title, .model-title {
    display: flex !important;
    align-items: center !important;
    color: var(--tc-gray) !important;
    font-style: normal !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: campton, sans-serif !important;
}

/*Colors*/
.bg-tellusr-primary {
    background: var(--tc-primary) !important;
}

.color-tellusr-primary {
    color: var(--tc-primary) !important;
}

.bg-light-tellusr {
    background-color: var(--tc-light) !important;
}

.bg-green-tellusr {
    background-color: var(--tc-green) !important;
}

.bg-gray-tellusr {
    background-color: var(--tc-gray) !important;
}

.bg-blue-soft-tellusr {
    background-color: var(--tc-blue-soft) !important;
}

/*Colors*/


/*Margins*/
/*TellusR Standard Margin*/
.tm {
    margin: 20px !important;
}

.tm-t {
    margin-top: 20px !important;
}

.tm-b {
    margin-bottom: 20px !important;
}

.tm-r {
    margin-right: 20px !important;
}

.tm-l {
    margin-left: 20px !important;
}

/*TellusR Standard Margin*/

/*TellusR Small Margin*/
.tsm {
    margin: 10px !important;
}

.tsm-t {
    margin-top: 10px !important;
}

.tsm-b {
    margin-bottom: 10px !important;
}

.tsm-r {
    margin-right: 10px !important;
}

.tsm-l {
    margin-left: 10px !important;
}

/*TellusR Small Margin*/
/*Margins*/


/*Paddings*/
/*TellusR Standard Padding*/
.tp {
    padding: 20px !important;
}

.tp-t {
    padding-top: 20px !important;
}

.tp-b {
    padding-bottom: 20px !important;
}

.tp-r {
    padding-right: 20px !important;
}

.tp-l {
    padding-left: 20px !important;
}

/*TellusR Standard Padding*/

/*TellusR Small Padding*/
.tsp {
    padding: 10px !important;
}

.tsp-t {
    padding-top: 10px !important;
}

.tsp-b {
    padding-bottom: 10px !important;
}

.tsp-r {
    padding-right: 10px !important;
}

.tsp-l {
    padding-left: 10px !important;
}

/*TellusR Small Padding*/
/*Paddings*/

.v-space {
    height: 20px !important;
}

.vs-space {
    height: 10px !important;
}

.h-space {
    width: 20px !important;
}

.hs-space {
    width: 10px !important;
}


.create-new-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 28px !important;
    min-width: 28px !important;
    border: 2px solid var(--tc-blue-soft);
    margin: auto 0 auto auto;
    cursor: pointer;
}

.list-header-item {
    color: var(--tc-blue-soft) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.list-item {
    color: var(--tc-soft-black) !important;
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif !important;
}


.tellusr-invalid-feedback {
    color: var(--bs-form-invalid-color);
    font-size: .875em;
    margin-top: .25rem;
    width: 100%;
}

.tellusr-selected-item {
    background-color: rgba(64, 176, 142, 0.1) !important;
}

.tellusr-suggest-wrapper{
    background: white!important;
}

.tellusr-suggested-item {
    background-color: rgba(140, 140, 140, 0.1) !important;
    border: 1px solid rgba(140, 140, 140, 0.25) !important;
}

.tellusr-suggested-item:hover {
    background-color: rgba(140, 140, 140, 0.20) !important;
}

.tellusr-toast-container {
    z-index: var(--bs-modal-zindex);
}